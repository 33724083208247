import React from 'react'
import AboutUs from '../component/aboutus/Aboutus'

const AboutUsPage = (props) => (
  <>
    <AboutUs {...props} />
  </>
)

export default AboutUsPage
