import React from 'react'

const UploadBanner = () => {
  return (
    <>
      <h1>UploadBanner</h1>
    </>
  )
}

export default UploadBanner
