import React from 'react'
import Home from '../component/home'

const HomePage = (props) => (
  <>
    <Home {...props} />
  </>
)

export default HomePage
