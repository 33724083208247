import React from 'react'

const DownloadBanners = () => {
  return (
    <>
      <h1>DownloadBanners</h1>
    </>
  )
}

export default DownloadBanners
